import React, { Component } from "react";

class DevFectaLogo extends Component {


    render() {

        return (

            <svg xmlns="https://www.w3.org/2000/svg" 
                height={ (this.props.logoHeight) ? this.props.logoHeight : "100%" } 
                width={ (this.props.logoWidth) ? this.props.logoWidth : "100%" } 
                viewBox="0 0 200 200" 
                preserveAspectRatio="none"
                className="headerLogo"
            >
                <path fill={ (this.props.logoColor) ? this.props.logoColor : "#003153" } d="M99.999,0C44.772,0,0,44.773,0,100c0,55.229,44.772,100,99.999,100C155.228,200,200,155.229,200,100
                    C200,44.773,155.228,0,99.999,0z M50.733,15.848c7.611-4.58,15.855-8.121,24.63-10.508c4.741,31.533,17.916,31.24,17.916,31.24
                    c-17.166,7.422-44.998,32.932-44.998,32.932C48.151,51,48.98,32.897,50.733,15.848z M109.981,197.623
                    c-2.942,0.265-5.881,0.423-8.89,0.423c-7.695,0-15.201-0.904-22.383-2.579c-17.823-11.145-27.439-49.71-27.439-49.71
                    c11.44,21.408,39.624,32.037,40.96,30.017c34.562-52.418,75.04-78.48,65.496-99.629C131.764,18.61,27.104,107.196,6.418,125.465
                    c-0.12-0.448-0.286-0.89-0.398-1.339C26.628,99.646,132.439-21.161,181.706,47.408c2.991,4.701,5.837,9.435,7.428,14.84
                    C200.459,100.757,145.971,135.686,109.981,197.623z"
                />
            </svg>

        );
    }

}

export default DevFectaLogo;